body {
  margin: 0;
  background: black;
  color: whitesmoke;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
