canvas {
  cursor: none;
}

canvas, #overlay {
  position: absolute;
}

li {
  padding: 4px 0;
}

#overlay {
  cursor: auto;
  padding: 24px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.55);
  border-top: 3px solid rgba(0, 0, 0, 0.7);
}

#profile-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

#profile-photo img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid whitesmoke;
}

#profile-greeting {
  font-family: 'Open Sans', sans-serif;
  font-size: 3em;
  font-weight: 800;
  white-space: nowrap;
  padding: 0 24px;
}

#profile-content {
  padding-top: 24px;
}

#profile-footer, #debug {
  padding-top: 12px;
  font-size: 0.8em;
  text-align: right;
}

@media screen and (min-width: 600px) {
  #overlay {
    bottom: 80px;
    right: 80px;
    border: 3px solid rgba(0, 0, 0, 0.7);
}

  #profile-header {
    justify-content: start;
  }

  #profile-greeting {
    text-align: start;
  }
}